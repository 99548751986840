            /*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
            
            @import "~@ng-select/ng-select/themes/default.theme.css";
            .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li.active>a {
                background: linear-gradient(118deg, #ffffff, rgb(255 255 255 / 100%)) !important;
                box-shadow: 0px 0px 6px 1px rgb(255 255 255 / 60%) !important;
                color: #1e9dd6 !important;
                font-weight: 900;
            }
            
            @media only screen and (min-width: 1199px) {
                .main-menu-content {
                    background: linear-gradient(180deg, rgba(7, 139, 203, 1) 0%, rgba(29, 156, 214, 1) 27%, rgba(79, 194, 239, 1) 100%) !important;
                }
            }
            
            .horizontal-menu .main-menu-content .dropdown-item {
                color: #ffffff !important;
                font-weight: 900;
            }
            
            .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover>a {
                background: #f8f8f8;
                color: #1e9dd6 !important;
            }
            
            .toast-title,
            .toast-close-button span {
                font-size: 15px !important;
                font-weight: bold !important;
            }
            
            .toast-container .ngx-toastr {
                font-size: 12px !important;
                font-weight: bold !important;
            }
            
            .toast-success {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #7ac14e;
                border-color: #7ac14e;
                color: #fff;
            }
            
            .toast-error {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #ea001e;
                border-color: #ea001e;
                color: #fff;
            }
            
            .toast-warning {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
                background-color: #fe9339;
                border-color: #fe9339;
                color: #fff;
            }
            
            .action-column {
                width: 150px;
            }
            
            .autocomplete-container .input-container input {
                border: 1px solid #c6c6c6 !important;
                border-radius: 5px;
                ;
            }
            
            .autocomplete-container {
                box-shadow: none !important;
            }
            
            ul.dropdown-menu.ng-star-inserted.show li a {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .main-menu-content {
                background-color: #40475c !important;
            }
            
            .dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>i,
            .dark-layout .horizontal-layout .main-menu-content .navbar-nav>li.active>a>svg {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li.active>a:hover {
                color: #1e9dd6 !important;
            }
            
            .dark-layout .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover>a {
                color: #f8f8f8 !important;
            }
            
            .dark-layout ul.dropdown-menu.ng-star-inserted.show li a {
                color: #f8f8f8 !important;
            }
            
            .opacity-50 {
                width: 50%;
                min-width: 150px;
            }
            
            .form-label {
                font-weight: 500;
            }
            
            .btn-xs {
                padding: 0.2rem !important;
            }
            
            .modal {
                z-index: 1050 !important;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
                background-color: #4ec1ee8f;
                border-radius: 3px;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
                border-right: 1px solid #ffffff;
            }
            
            .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
                background-color: #ff8f87;
                border-radius: 3px;
            }
            /* width */
            
             ::-webkit-scrollbar {
                width: 10px;
                border-radius: 10px;
            }
            /* Track */
            
             ::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }
            /* Handle */
            
             ::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }
            /* Handle on hover */
            
             ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }